import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Collapse,
  Grid,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import MyFileUpload from "../../fields/MyFileUpload";
import { StaticImage } from "gatsby-plugin-image";
import { Formik, Form } from "formik";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Box } from "@material-ui/core";
import { addQuote } from "../../../api";
import { navigate } from "gatsby";
import addVehicleScheme from "../../../helper/schemes/addVehicleScheme";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import Loading from "../../Loading";
import useStyles from "../../../styles/addVehicle";
import Link from "../../Link";
import MySelect from "../../fields/MySelect";

const Vehicle = ({ setMessage }) => {
  const [open, setOpen] = useState(false);
  const [showVoucher, setShowVoucher] = useState(false);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isSucceed, setIsSucceed] = useState(null);
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  const submitData = (data, formikProps) => {
    const unauthorizedCallback = () => {
      if (typeof window !== "undefined") {
        navigate("/login");
      }
    };

    setLoading(true);
    addQuote(data, unauthorizedCallback).then((success) => {
      if (success) {
        formikProps.resetForm();
        setOpen(false);
        setIsSucceed(success);
      }
      setMessage();
      setLoading(false);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setIsSucceed(null);
    }, 5000);
  }, [isSucceed]);

  if (loading) return <Loading />;

  return (
    <Formik
      initialValues={{
        frontUpload: "",
      }}
      validationSchema={addVehicleScheme}
      onSubmit={(values, formikProps) => {
        submitData(values, formikProps);
      }}
    >
      {() => (
        <Form className={classes.login}>
          <div className={classes.root}>
            <Grid container>
              <Grid item xs={12} align="start">
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleClick}
                  className={classes.btn}
                >
                  <AddCircleIcon className={classes.icon} />
                  <Typography variant="body1">Fahrzeug hinzufügen</Typography>
                </Button>
              </Grid>
            </Grid>

            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              className={classes.collapse}
            >
              <Paper elevation={0} className={classes.paper}>
                <Grid container className={classes.container}>
                  <Grid item xs={12} md={8} lg={8}>
                    <div className="mt-12 w-full lg:w-[957px]">
                      <p className="text-[#070606] text-[1rem] font-[400] leading-[1.5rem] pb-12">
                        <span>
                          Trotz der aktuell niedrigen Quotenmenge pro Fahrzeug
                          und den dazugehörigen gesunkenen Prämien gegenüber den
                          Vorjahren, bietet Ihnen M3E auch dieses Jahr einen
                          transparenten und garantierten Festerlös an. Mehr
                          Information zum Fall der Quotenmenge und Neuigkeiten
                          finden Sie{" "}
                          <Link href="/faq" className="underline">
                            hier
                          </Link>{" "}
                          oder in unserem{" "}
                          <a
                            rel="noreffere noopener noreferrer"
                            target="_blank"
                            href="https://m3e.group/newsletter"
                            className="underline"
                          >
                            Newsletter
                          </a>
                          .
                        </span>
                      </p>
                    </div>
                    <ButtonGroup
                      variant="contained"
                      aria-label="Basic button group"
                      className="voucherButtonGroup"
                      style={{ marginBottom: "30px" }}
                    >
                      <Button onClick={() => setShowVoucher(false)}>
                        Auszahlung Geld
                      </Button>
                      <Button onClick={() => setShowVoucher(true)}>
                        Gutschein
                      </Button>
                    </ButtonGroup>
                    {showVoucher && (
                      <MySelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="voucher"
                        label="Bitte wählen Sie einen Gutschein aus"
                        options={[
                          {
                            label: "THG Gutschein Charge & Co",
                            value: "THGV_001",
                          },
                          {
                            label: "THG Gutschein Paper & Sons",
                            value: "THGV_002",
                          },
                          {
                            label: "THG Gutschein GreenShirt",
                            value: "THGV_003",
                          },
                          {
                            label: "THG Gutschein GoodBuy",
                            value: "THGV_004",
                          },
                        ]}
                      />
                    )}
                    <Typography
                      component="h1"
                      variant="h6"
                      className={classes.text}
                    >
                      Zulassungsbescheinigung Teil 1
                    </Typography>
                    <Typography
                      component="h2"
                      variant="body1"
                      color="secondary"
                    >
                      (Vorderseite)
                    </Typography>
                    <div className={classes.upload}>
                      <Typography variant="body2">
                        Wählen Sie per Klick eine Datei auf das Feld oder legen
                        Sie sie hier ab (JPG, PNG, PDF)
                      </Typography>
                      <Box my={4} className={classes.uploadBox}>
                        <MyFileUpload
                          label="Vorderseite"
                          id="frontUpload"
                          name="frontUpload"
                        />
                        <StaticImage
                          src="../../../media/images/Zulassungsbescheinigung1.jpg"
                          className={classes.image1}
                          alt="Zulassungsbescheinigung1"
                        />
                      </Box>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    lg={5}
                    className={classes.zulassung}
                  ></Grid>
                  <Grid item xs={12} className={classes.gridBtn}>
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.saveBtn}
                    >
                      Speichern
                    </Button>
                  </Grid>

                  <Grid item xs={4}></Grid>
                </Grid>{" "}
              </Paper>
            </Collapse>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Vehicle;
